<template>
  <div class='indexbox'>
    <div class="searchbox">
      <div class="left" v-hasPermi="['aol']">
        <el-input class="inp" size='small' v-model="keywords" placeholder="Type Text To Search" clearable
          style="width: 200px" @change='pagechage(1)'></el-input>
        <el-date-picker v-model="timearr" style="max-width:280px;margin-right:10px;" format='yyyy-MM-dd'
          value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="WIP Date Start"
          end-placeholder="End" clearable @change='pagechage(1)' />
        <el-date-picker v-model="timearra" style="max-width:350px;margin-right:10px;" format='yyyy-MM-dd'
          value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="Completion Date Start"
          end-placeholder="End" clearable @change='pagechage(1)' />
        <el-date-picker v-model="timearrb" style="max-width:340px;margin-right:10px;" format='yyyy-MM-dd HH:mm'
          value-format="yyyy-MM-dd HH:mm" type="datetimerange" range-separator="-"
          start-placeholder="Generated Date Start" end-placeholder="End" clearable @change='pagechage(1)' />

        <el-select size='small' style="max-width:160px" clearable v-model="assembly_status" placeholder="Status"
          @change='pagechage(1)'>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="right">
        <el-button class="download" v-hasPermi="['aaoi']" type="primary" icon="el-icon-download"
          @click="$router.push('listImport')">Import</el-button>
        <el-dropdown v-hasPermi="['aola']" @command="handleCommand">
          <el-button type="primary">
            Allocate<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="Allocate">Allocate</el-dropdown-item>
            <el-dropdown-item command="Excel">Allocate via Excel</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown v-hasPermi="['aolc']" @command="handleCommandb">
          <el-button type="primary">
            Complete<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="Complete">Complete</el-dropdown-item>
            <el-dropdown-item command="Excel">Complete via Excel</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown v-hasPermi="['aolv']" @command="handleVoid">
          <el-button type="primary">
            Void<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="Void">Void</el-dropdown-item>
            <el-dropdown-item command="Import">Void via Excel</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- <el-popconfirm title="The selected assembly orders will be voided. Continue or not?" @confirm='btnvoid'>
          <el-button slot="reference" type="primary" v-hasPermi="['aolv']" :loading='btn_loadingc'>Void</el-button>
        </el-popconfirm> -->

        <el-button type="primary" :loading='btn_loadinge' @click="listStatus">Sync Status</el-button>
        <el-button type="primary" v-hasPermi="['aba']" @click="$router.push('importAmendBom')">Amend BOM for Authorized
          Assembly</el-button>
        <el-button type="primary" :loading='btn_loadingf' @click="orders_export">Export Order</el-button>
        <el-button type="primary" :loading='btn_loadingh' @click="ordersdel">Delete Order</el-button>
      </div>
    </div>
    <div class="tablebox">
      <div class="draggable" v-loading='loading'>
        <el-table :data="tableData" stripe @selection-change="SelectionChange" max-height='700px' show-summary
          :summary-method="getSummaries">
          <el-table-column type="selection" fixed align='center' width='60' />
          <el-table-column prop="AssemblyNumber" label="Assembly Order #" width='150' align='center' fixed />
          <el-table-column prop="Location" label="Location" align='center' width='120' show-overflow-tooltip />
          <el-table-column prop="ProductCode" label="SKU" align='center' show-overflow-tooltip />
          <el-table-column prop="ProductName" label="Product Name" width='140' align='center' show-overflow-tooltip />
          <el-table-column prop="Quantity" label="Quantity" sortable width='150' align='center' show-overflow-tooltip />
          <el-table-column prop="WIPDate" label="WIP Date" sortable min-width='150' align='center'
            show-overflow-tooltip />
          <el-table-column prop="CompletionDate" label="Completion Date" sortable min-width='180' align='center'
            show-overflow-tooltip />
          <el-table-column prop="GeneratedDate" label="Generated Date" sortable min-width='160' align='center'
            show-overflow-tooltip />
          <el-table-column prop="VoidedDate" label="Voided Date" sortable width='140' align='center'
            show-overflow-tooltip />

          <el-table-column prop="Note" label="Note" align='center' show-overflow-tooltip />
          <el-table-column prop="Status" label="Status" align='center' show-overflow-tooltip />
          <el-table-column prop="SO" label="SO#" align='center' show-overflow-tooltip />

          <!-- <el-table-column label="pick/complete/void" min-width='120' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.is_pick==1?'Yes':'No'}}</span>/
              <span>{{scope.row.is_finish==1?'Yes':'No'}}</span>/
              <span>{{scope.row.is_delete==1?'Yes':'No'}}</span>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <el-pagination @current-change='pagechage' @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]"
        background layout="total,sizes,prev, pager, next" v-show="total > 0" :current-page.sync="page"
        :page-size="page_size" :total="total" />
    </div>
    <el-dialog :visible.sync="productavailability_show" width="90%" :show-close=false>
      <div class="topbox" slot="title">
        <div class="left">Replenishment List</div>
        <div class="right">
          <el-dropdown v-hasPermi="['aola']" @command="ReplenishmentExport">
            <el-button type="primary">
              Export<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="List">List Export</el-dropdown-item>
              <el-dropdown-item command="Detail">Detail Export</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="primary" style="margin-left:10px;" v-hasPermi="['csl']" :loading='btn_loadingd'
            @click="stockadjustment">Generate Write
            On & Allocate</el-button>
          <el-button type="primary" @click="productavailability_show = false">Cancel</el-button>
        </div>
      </div>
      <el-table v-hasPermi="['col']" :data="productavailability_list" stripe @selection-change="SelectionChangelist"
        max-height="400" v-loading='loadinga'>
        <!-- <el-table-column type="selection" fixed align='center' /> -->
        <el-table-column label="Component SKU" prop="ProductCode" width="140" align="center" show-overflow-tooltip
          fixed />
        <el-table-column label="Component Name" prop="Name" width="160" align="center" show-overflow-tooltip />
        <el-table-column label="Component Required Qty" prop="require_qty" align="center" width="220"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color:#19CBBF;font-weight:550;cursor:pointer;" @click="shortageDetail(scope.row)">{{
              scope.row.require_qty
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Component Available Qty" prop="Available" align="center" width="220"
          show-overflow-tooltip />
        <el-table-column label="Stock On Hand" prop="OnHand" align="center" width="140" show-overflow-tooltip />
        <el-table-column label="Shortage Qty" prop="Shortage" align="center" min-width="140" show-overflow-tooltip />
        <el-table-column label="Unit Price For 0 Stock" prop="UnitPrice" min-width="180" align="center"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input :disabled='scope.row.OnHand == 0 && !scope.row.UnitPrice ? false : true'
              v-model="scope.row.UnitPrice" placeholder="Please Enter"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="BatchNumber For 0 Stock" prop="BatchNubmer" min-width="200" align="center"
          show-overflow-tooltip />
      </el-table>
    </el-dialog>
    <el-dialog title="Component Shortage Detail" :visible.sync="detail_show" width="95%">
      <el-table :data="detail_list" stripe max-height="400" v-hasPermi="['csd']">
        <el-table-column label="Assembly Order #" prop="AssemblyNumber" align="center" width='160' show-overflow-tooltip
          fixed />
        <el-table-column label="Location" prop="Location" align="center" width='120' show-overflow-tooltip />
        <el-table-column label="SKU" prop="ProductCode" align="center" show-overflow-tooltip />
        <el-table-column label="Product Name" prop="ProductName" align="center" width='130' show-overflow-tooltip />
        <el-table-column label="Quantity" prop="Quantity" align="center" sortable width='140' show-overflow-tooltip />
        <el-table-column label="WIP Date" prop="WIPDate" sortable width='150' align="center" show-overflow-tooltip />
        <el-table-column label="Completion Date" prop="CompletionDate" sortable width='180' align="center"
          show-overflow-tooltip />
        <el-table-column label="Note" prop="Note" align="center" show-overflow-tooltip />
        <el-table-column label="SO#" prop="SO" align="center" show-overflow-tooltip />
        <el-table-column label="Component SKU" width='150' prop="ComponentSKU" align="center" show-overflow-tooltip />
        <el-table-column label="Component Name" width='150' prop="ComponentName" align="center" show-overflow-tooltip />
        <el-table-column label="Component Requried Qty" width='220' prop="TotalQuantity" align="center"
          show-overflow-tooltip />
      </el-table>
    </el-dialog>

    <el-dialog title="Import Void Orders" :visible.sync="import_show" width="35%" @close="dialogClose">
      <div class="btntop">
        <el-button @click="downloaddata" :loading='down_loading'>Download Excel Template</el-button>
      </div>
      <el-upload :limit='1' class="upload-demo" action='' drag :auto-upload="false" accept=".xlsx, .xls"
        :on-change="Uploadchange" :on-remove='clearFiles' :file-list="fileList">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drag The File Here, Or<em>Click Upload</em></div>
        <div style="color:red" slot="tip">Tip: Only Import Is Allowed “xls” or “xlsx” Format File！</div>
      </el-upload>
      <div class="btnbox">
        <el-button v-hasPermi="['tol']" :disabled="!file" type="primary" :loading='import_loading'
          @click="importOrders">Import
          Void Orders</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      options: [
        {
          value: 'AUTHORISED',
          label: 'AUTHORISED'
        },
        {
          value: 'IN PROGRESS',
          label: 'IN PROGRESS'
        },
        {
          value: 'COMPLETED',
          label: 'COMPLETED'
        },
        {
          value: 'VOIDED',
          label: 'VOIDED'
        },
      ],
      timearr: [],
      timearra: [],
      timearrb: [],
      assembly_status: '',
      keywords: '',
      total: 0,
      page: 1,
      page_size: 10,
      tableData: [],
      loading: false,
      idsarr: [],
      btn_loading: false,
      btn_loadingc: false,
      btn_loadingd: false,
      btn_loadinge: false,
      btn_loadingf: false,
      btn_loadingh: false,

      loadinga: false,
      productavailability_show: false,
      productavailability_list: [],//判断组装库存 并返回补货列表
      listidsarr: [],//选中的补货列表
      ids: '',

      detail_show: false,
      detail_list: [],
      Quantity: 0,

      import_show: false,
      fileList: [],
      file: '',
      import_loading: false,
      down_loading: false
    };
  },
  computed: {
    ...mapGetters(['api_id']),
  },
  methods: {
    //列表
    getlist() {
      this.loading = true
      this.$post({
        url: "/app/assembly_orders/pageList",
        data: {
          page: this.page,
          page_size: this.page_size,
          keywords: this.keywords,
          status: this.assembly_status,
          api_id: this.$store.state.user.api_id,
          start_time: this.timearr && this.timearr.length ? this.timearr[0] : '',
          end_time: this.timearr && this.timearr.length ? this.timearr[1] : '',
          start_time2: this.timearra && this.timearra.length ? this.timearra[0] : '',
          end_time2: this.timearra && this.timearra.length ? this.timearra[1] : '',
          start_time3: this.timearrb && this.timearrb.length ? this.timearrb[0] : '',
          end_time3: this.timearrb && this.timearrb.length ? this.timearrb[1] : '',
        },
        success: (res) => {
          this.page = res.data.current_page
          this.page_size = Number(res.data.per_page)
          this.tableData = res.data.data
          this.total = res.data.total
          this.Quantity = res.data.Quantity
          setTimeout(() => {
            this.loading = false
          }, 200);
        },
        tip: () => {
          this.loading = false
        },
      })
    },
    // 分页
    pagechage(page) {
      this.page = page
      this.tableData = []
      this.getlist()
    },
    // 分页
    handleSizeChange(size) {
      this.page_size = size
      this.tableData = []
      this.getlist()
    },
    // 多选
    SelectionChange(data) {
      this.idsarr = data
    },

    // 更多操作触发
    handleCommand(command) {
      switch (command) {
        case "Allocate":
          this.productavailability();
          break;
        case "Excel":
          this.$router.push('importAllocate')
          break;
        default:
          break;
      }
    },
    //组装库存 并返回补货列表
    productavailability() {
      if (this.loading) return;
      this.productavailability_list = []
      if (!this.idsarr.length) {
        this.$message({
          message: 'Please Select Assembly Order',
          type: 'warning'
        });
        return
      }
      let ids = []
      this.idsarr.map((item) => {
        ids.push(item.id)
      })
      this.loading = true
      this.$post({
        url: "/app/assembly_orders/allocateTip",
        data: {
          api_id: this.$store.state.user.api_id,
          ids: ids.join()
        },
        success: (res) => {
          this.setproductavailability(ids.join())
        },
        showtip: (res) => {
          this.$confirm(res.msg, {
            type: 'warning'
          }).then(() => {
            this.setproductavailability(ids.join())
          }).catch(() => {
            this.loading = false
          });
        },
        tip: () => {
          setTimeout(() => {
            this.loading = false
          }, 1300);
        },
      })
    },
    //组装库 补货列表
    setproductavailability(ids) {
      this.$post({
        url: "/app/assembly_orders/productavailability",
        data: {
          api_id: this.$store.state.user.api_id,
          ids
        },
        success: (res) => {
          if (!res.data.length) {
            this.allocate(ids)
          } else {
            let arr = []
            res.data.forEach(item => {
              item.is_UnitPrice = false
              if (item.UnitPrice === '') {
                item.is_UnitPrice = true
              }
              arr.push(item)
            });
            //库存不够 补货
            this.ids = ids
            this.loading = false
            this.productavailability_list = arr
            this.productavailability_show = true
          }
        },
        tip: () => { },
      })
    },
    //组装库 补货列表 导出
    ReplenishmentExport(command) {
      switch (command) {
        case "List":
          this.listExport();
          break;
        case "Detail":
          this.detailExport()
          break;
        default:
          break;
      }
    },
    //补货列表 列表导出
    listExport() {
      if (this.loadinga) return
      this.loadinga = true
      let data = JSON.stringify(this.productavailability_list)
      setTimeout(() => {
        this.loadinga = false
      }, 1200);
      let url = `${this.httpPath}/app/assembly_orders/stockadjustmentExport?token=${getToken()}&api_id=${this.$store.state.user.api_id}&data=${data}`
      window.open(url)
    },
    //补货列表 明细导出
    detailExport() {
      if (this.loadinga) return
      this.loadinga = true
      let ids = this.idsarr.map(val => val.id).join()
      setTimeout(() => {
        this.loadinga = false
      }, 1200);
      let url = `${this.httpPath}/app/assembly_orders/shortageDetailExport?token=${getToken()}&api_id=${this.$store.state.user.api_id}&ids=${ids}`
      window.open(url)
    },
    // allocate 库存够 分拣
    allocate(ids) {
      this.loading = true
      this.$post({
        url: "/app/assembly_orders/allocate",
        data: {
          api_id: this.$store.state.user.api_id,
          ids
        },
        success: (res) => {
          this.ids = ''
          this.$notify({
            title: res.msg,
            type: 'success'
          });
          this.loading = false
          setTimeout(() => {
            this.pagechage(1)
          }, 1300);
        },
        tip: () => {
          this.loading = false
        },
      })
    },
    //库存不足 选择
    SelectionChangelist(data) {
      this.listidsarr = data
    },
    // 调整库存
    stockadjustment() {
      let data = []
      this.productavailability_list.map((item) => {
        data.push({
          SKU: item.ProductCode,
          Quantity: item.Shortage,
          UnitCost: item.UnitPrice,
          BatchSN: item.BatchNubmer,
          require: item.require_qty,
          ProductID: item.ProductID,
        })
      })
      this.loadinga = true
      this.btn_loadingd = true
      this.$post({
        url: "/app/assembly_orders/stockadjustment",
        data: {
          api_id: this.$store.state.user.api_id,
          data: JSON.stringify(data)
        },
        success: (res) => {
          this.productavailability_show = false
          this.allocate(this.ids)
          setTimeout(() => {
            this.loadinga = false
            this.btn_loadingd = false
          }, 1300);
        },
        tip: () => {
          setTimeout(() => {
            this.loadinga = false
            this.btn_loadingd = false
          }, 1300);
        },
      })
    },
    // shortageDetail
    shortageDetail(row) {
      this.detail_list = []
      this.loadinga = true
      let ids = []
      this.idsarr.map((item) => {
        ids.push(item.id)
      })
      this.$post({
        url: "/app/assembly_orders/shortageDetail",
        data: {
          api_id: this.$store.state.user.api_id,
          ids: ids.join(),
          ProductID: row.ProductID
        },
        success: (res) => {
          this.loadinga = false
          this.detail_list = res.data
          this.detail_show = true
        },
        tip: () => {
          this.loadinga = false
        },
      })
    },
    // Complete导入
    handleCommandb(command) {
      switch (command) {
        case "Complete":
          this.Complete();
          break;
        case "Excel":
          this.$router.push('importComplete')
          break;
        default:
          break;
      }
    },
    //Complete
    Complete() {
      if (this.loading) return;
      if (!this.idsarr.length) {
        this.$message({
          message: 'Please Select Assembly Order',
          type: 'warning'
        });
        return
      }
      let ids = []
      this.idsarr.map((item) => {
        ids.push(item.id)
      })
      this.loading = true
      this.$post({
        url: "/app/assembly_orders/completeTip",
        data: {
          api_id: this.$store.state.user.api_id,
          ids: ids.join()
        },
        success: (res) => {
          this.setcomplete(ids.join())
        },
        showtip: (res) => {
          this.$confirm(res.msg, {
            type: 'warning'
          }).then(() => {
            this.setcomplete(ids.join())
          }).catch(() => {
            this.loading = false
          });
        },
        tip: () => { },
      })
    },
    setcomplete(ids) {
      this.$post({
        url: "/app/assembly_orders/complete",
        data: {
          api_id: this.$store.state.user.api_id,
          ids
        },
        success: (res) => {
          this.$notify({
            title: res.msg,
            type: 'success'
          });
          this.loading = false
          this.$nextTick(() => {
            this.pagechage(1)
          })
        },
        tip: () => {
          this.loading = false
        },
      })
    },
    // Void
    handleVoid(command) {
      switch (command) {
        case "Void":
          this.$confirm('The selected assembly orders will be voided. Continue or not?', '', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning'
          }).then(() => {
            this.btnvoid()
          }).catch(() => {

          });
          break;
        case "Import":
          this.import_show = true
          break;
        default:
          break;
      }
    },
    //void
    btnvoid() {
      if (!this.idsarr.length) {
        this.$message({
          message: 'Please Select Assembly Order',
          type: 'warning'
        });
        return
      }
      let ids = []
      this.idsarr.map((item) => {
        ids.push(item.id)
      })
      this.btn_loadingc = true
      this.$post({
        url: "/app/assembly_orders/voidTip",
        data: {
          api_id: this.$store.state.user.api_id,
          ids: ids.join()
        },
        success: (res) => {
          this.setvoid(ids.join())
        },
        showtip: (res) => {
          this.$confirm(res.msg, {
            type: 'warning'
          }).then(() => {
            this.setvoid(ids.join())
          }).catch(() => {
            this.btn_loadingc = false
          });
        },
        tip: () => {
          setTimeout(() => {
            this.btn_loadingc = false
          }, 1300);
        },
      })
    },
    setvoid(ids) {
      this.$post({
        url: "/app/assembly_orders/void",
        data: {
          api_id: this.$store.state.user.api_id,
          ids
        },
        success: (res) => {
          this.$notify({
            title: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            this.btn_loadingc = false
            this.pagechage(1)
          }, 1300);
        },
        tip: () => {
          setTimeout(() => {
            this.btn_loadingc = false
          }, 1300);
        },
      })
    },
    //导入模板下载
    downloaddata() {
      this.down_loading = true
      this.$post({
        url: "/app/assembly_orders/getExcelUrl",
        success: (res) => {
          let url = res.data.url6
          window.open(url)
          setTimeout(() => {
            this.down_loading = false
          }, 1200);
        },
        tip: () => {
          setTimeout(() => {
            this.down_loading = false
          }, 1200);
        },
      })
    },
    // 导入 上传file
    Uploadchange(file) {
      this.file = file.raw
    },
    clearFiles() {
      this.fileList = []
      this.file = ''
    },
    dialogClose() {
      this.import_loading = false
      this.fileList = []
      this.file = ''
      this.import_show = false
    },
    importOrders() {
      this.import_loading = true
      let data = new FormData();
      data.append("file", this.file);
      data.append("api_id", this.$store.state.user.api_id);
      data.append("token", getToken());
      this.$post({
        url: "/app/assembly_orders/importVoidOrders",
        upload: true,
        data,
        success: (res) => {
          this.dialogClose()
          this.$message({
            message: 'Success',
            type: 'success',
            onClose: () => {
              this.pagechage(1)
            }
          });
        },
        tip: () => {
          this.dialogClose()
          setTimeout(() => {
            this.pagechage(1)
          }, 1500);
        },
      })
    },
    // Sync Status
    listStatus() {
      this.btn_loadinge = true
      this.$post({
        url: "/app/assembly_orders/syncStatus",
        data: {
          api_id: this.$store.state.user.api_id,
        },
        success: (res) => {
          this.$notify({
            title: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            this.btn_loadinge = false
            this.pagechage(1)
          }, 1300);
        },
        tip: () => {
          setTimeout(() => {
            this.btn_loadinge = false
          }, 1300);
        },
      })
    },
    // 导出组装单列表 Export Order
    orders_export() {
      this.btn_loadingf = true
      setTimeout(() => {
        this.btn_loadingf = false
      }, 1200);
      let obj = {
        page: this.page,
        page_size: this.page_size,
        keywords: this.keywords,
        status: this.assembly_status,
        api_id: this.$store.state.user.api_id,
        start_time: this.timearr && this.timearr.length ? this.timearr[0] : '',
        end_time: this.timearr && this.timearr.length ? this.timearr[1] : '',
        start_time2: this.timearra && this.timearra.length ? this.timearra[0] : '',
        end_time2: this.timearra && this.timearra.length ? this.timearra[1] : '',
        start_time3: this.timearrb && this.timearrb.length ? this.timearrb[0] : '',
        end_time3: this.timearrb && this.timearrb.length ? this.timearrb[1] : '',
      }
      let data = ''
      for (let key in obj) {
        data += `${key}=${obj[key]}&`
      }
      let url = `${this.httpPath}/app/assembly_orders/export?token=${getToken()}&${data}`
      window.open(url)
    },
    // Delete Order
    ordersdel() {
      if (!this.idsarr.length) {
        this.$message({
          message: 'Please Select Assembly Order',
          type: 'warning'
        });
        return
      }
      let ids = []
      this.idsarr.map((item) => {
        ids.push(item.id)
      })
      this.btn_loadingh = true
      this.$post({
        url: "/app/assembly_orders/del",
        data: {
          api_id: this.$store.state.user.api_id,
          ids: ids.join()
        },
        success: (res) => {
          this.$notify({
            title: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            this.btn_loadingh = false
            this.pagechage(1)
          }, 1300);
        },
        tip: () => {
          setTimeout(() => {
            this.btn_loadingh = false
          }, 1300);
        },
      })
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Sum';
          return;
        }
        switch (column.property) {  // column.property可以匹配它的每一列的命名， 然后赋值
          case "Quantity":
            sums[index] = this.Quantity //值取自后台
            break;
          default:
            break;
        }
      });
      return sums;
    },
  },
  created() {
    this.getlist()
  },
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  height: 100%;
  .searchbox {
    width: 100%;
    padding: 6px 15px;
    background: #fff;
    border-bottom: 1px solid #babdcd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .inp {
        margin-right: 10px;
      }
      .topboxs {
        position: relative;
        padding: 10px;
        .tit {
          position: absolute;
          top: 0px;
          left: 20px;
          padding: 2px 4px;
          background-color: #fff;
          z-index: 10;
          line-height: 1;
          font-size: 14px;
        }
      }
      /deep/.el-button {
        margin: 5px 10px;
      }
    }
    .download {
      background: #fff !important;
      color: #19cbbf !important;
    }
    /deep/.el-button {
      background-color: #d6f3e3;
      border-radius: 10px;
      color: #3b3b3b;
      border-color: #d6f3e3;
      align-self: flex-end;
      margin: 5px;
    }
  }
  .tablebox {
    width: 100%;
    padding: 20px;
  }
  /deep/.el-dialog {
    min-width: 400px !important;
    .topbox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-weight: 550;
      }
    }
    .el-dialog__body {
      padding-top: 10px;
    }
  }
  /deep/.el-dialog {
    min-width: 400px !important;
    .topbox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-weight: 550;
      }
    }
    .el-dialog__body {
      padding-top: 10px;
    }
  }
  /deep/.el-upload {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .btntop {
    width: 100%;
    text-align: right;
    padding: 0 0 15px;
  }
  .btnbox {
    width: 100%;
    text-align: center;
    padding: 20px 0 0;
    /deep/.el-button {
      width: 88%;
      height: 40px;
      font-size: 15px;
    }
  }
}
</style>
